import { state, Cmp, Ctrl } from ":mods";
import { CreateAccountProps, SignupViewProps } from "../../models";
import { Match, Show, Switch, createSignal, splitProps } from "solid-js";
import { createAccountInputs } from "../../forms";
import { A, useSearchParams } from "@solidjs/router";
import { ROUTES } from "../../const";
import { createAccount, logout, patchAccountOtp } from "../../apis";
import { OTP } from "./otp";
import { _token_actions, actions } from "../../store";

export function Signup(props: SignupViewProps) {
  const [local, others] = splitProps(props, ["$"]);
  const [params, setParams] = useSearchParams();
  const [userData, setUserData] = createSignal();
  const app_flavor = Ctrl.Context.hooks.useAppFlavor();
  const app_meta = Ctrl.Context.hooks.useMeta();
  const signup_type = params.type === "tutor" ? "tutor" : "student";
  app_meta.setTitle("Create an Account");
  //
  const FormAccount = createAccountInputs("student");
  const $reset_pass = state.create(false);
  const $totp = state.create(false);

  async function onCreateAccount(e: Event) {
    const values = FormAccount.Actions.getValuesLowercase();
    await createAccount(values, signup_type)
      .then(() => {
        local.$.actions.pushToast({ type: "success", message: "Account Created" });
        setUserData(values);
      })
      .catch((e) => {
        local.$.actions.pushToast({ type: "error", message: "Invalid Signup" });
      });
  }

  async function onSubmitOTP(body) {
    console.log("otp entered: ", body);
    body["email"] = userData()?.["email"];
    await patchAccountOtp(body as any)
      .then(() => {
        local.$.actions.pushToast({ type: "success", message: "Correct OTP" });
        local.$.actions.navigate(ROUTES.auth.login);
        if (actions.hasToken()) {
          _token_actions.rm();
          logout(); /////
        }
      })
      .catch(({response}) => {
        const {error} = response?.data || []
        local.$.actions.pushToast({ type: "error", message: error[0] });
      });
  }

  return (
    <Show
      when={!userData()}
      fallback={
        <OTP
          onOTPSuccess={onSubmitOTP}
        />
      }
    >
      <section class="flex flex-col !font-rubik$ w-full h-full items-center justify-center">
        <form class="flex flex-col gap-15px !font-rubik$ w-500px h-fit bg#pt text#p rounded-lg p-40px ">
          <h1 class={`flex flex-col items-center font-bold text-31px mb-20px`}>
            <span class="capitalize text-40px">Museum Training</span>
            <Switch fallback={<p class="capitalize self-center">{signup_type + " Sign up " ?? app_flavor.id}</p>}>
              <Match when={$reset_pass.value}>Email Verification</Match>
              <Match when={$totp.value}>OTP Auth</Match>
            </Switch>
          </h1>
          <Switch fallback={null}>
            <Match when={!$totp.value} keyed={true}>
              <FormAccount.Email.Input
                autocomplete="username"
                type="email"
                class="form-input text-0.8rem px-2.5 py-2"
                placeholder="Email"
                id="email"
              />
              <Show when={!!FormAccount.Email.Error}>
                <FormAccount.Email.Error
                  class={`${FormAccount.Email.control.isTouched ? "form-input-hint" : "form-input-error"}`}
                />
              </Show>

              <div class="flex flex-col gap-5px">
                <FormAccount.Password.Input
                  autocomplete="current-password"
                  type="password"
                  class="form-input"
                  placeholder="Password"
                  id="password"
                />
                <Show when={FormAccount.Password.Error}>
                  <FormAccount.Password.Error
                    class={`${FormAccount.Password.control.isTouched ? "form-input-hint" : "form-input-error"}`}
                  />
                </Show>
              </div>
              <div class="flex flex-col gap-5px">
                <FormAccount.ConfirmPassword.Input
                  autocomplete="current-password"
                  type="password"
                  class="form-input"
                  placeholder="Confirm Password"
                  id="confirm-password"
                />
                <Show when={FormAccount.ConfirmPassword.Error}>
                  <FormAccount.ConfirmPassword.Error
                    class={`${FormAccount.ConfirmPassword.control.isTouched ? "form-input-hint" : "form-input-error"}`}
                  />
                </Show>
              </div>
            </Match>
          </Switch>

          <FormAccount.Actions.Button
            class="form-btn flex$  min-h-2rem text-0.8rem px-2 py-1 fight$ my-24px"
            onclick={onCreateAccount}
            statusValid={"Sign up"}
            statusInvalid={"Sign up"}
          />

          <div class="text-xs !mt24x text-center cursor-pointer">
            Do you already have an account?
            <A class=" text-16px p-0 mx-1 underline text#p" href={`..${ROUTES.auth.login}`}>
              Log in
            </A>
          </div>
        </form>
      </section>
    </Show>
  );
}
